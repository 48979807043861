<template>
  <div id="newsStoryContent">
    <p id="Text1">
      <!-- pic11F6A94C216B3DB80532CA879B2C7EAF.jpg -->
      <img src="" class="rounded">
      <br>
      <br>
      The last huge American industry event of the year, PAX (The Penny Arcade Expo) in Seattle,
      turned out to be everything LEGO® Universe had hoped for and more.
      <br><br>
      Everyone from hardcore cosplayers to families that enjoy gaming together stopped by the
      swarming LEGO Universe game kiosks to check out all the cool features in the game and
      share their enthusiasm with the team on the floor.
      <br><br>
      As we are getting closer to launch, the media schedule was just as packed and the reactions
      were overwhelming. A majority of the press turned out to be parents themselves, and
      emphasized how awesome it is to have a solid family MMO in the making.
      <br><br>
      During all three days we offered fun and entertaining giveaways, and chances are that
      somewhere in America right now, someone is looking really cool in a new LEGO Universe t-shirt!
      <br><br>
      <!-- pic6DD7DC3B1390925D716B1C70628B3B46.jpg -->
      <img src="" class="rounded">
    </p>
  </div>
</template>
